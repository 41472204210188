/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react';

import { IDynamicsFormMapping } from '@/types/dynamicsForms';
import { getCookie } from '@lamesarv-sdk/tools';

import { useDeviceId } from './device';
import { useEnv } from './useEnv';

export interface IDynamicsFormInput {
  formId: string;
  mappings: IDynamicsFormMapping[];
  dynamicsFormId: string;
  callback?: () => void;
  debug?: boolean;
}

export const useDynamicsForms = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const deviceId = useDeviceId();
  const { env } = useEnv();

  const sendForm = async (props: IDynamicsFormInput): Promise<void> => {
    const client = (window as any).d365mktformcapture;

    if (!client) {
      if (props.debug) {
        console.error('dynamicsFormSubmit::client-not-found');
      }
      return;
    }

    try {
      setLoading(true);
      setError(false);

      if (props.debug) {
        console.log('dynamicsFormSubmit::formId', props.formId);
        console.log('dynamicsFormSubmit::mappings', props.mappings);
        console.log('dynamicsFormSubmit::config', props.dynamicsFormId);
      }

      const form = await client.waitForElement(props.formId);
      const formSerialized = client.serializeForm(form, [
        {
          DataverseFieldName: 'lmrv_deviceid',
          DataverseFieldValue: deviceId || '',
        },
        {
          DataverseFieldName: 'lmrv_utmsource',
          DataverseFieldValue: getCookie('rwa_source') || '',
        },
        {
          DataverseFieldName: 'lmrv_utmcampaign',
          DataverseFieldValue: getCookie('rwa_campaign') || '',
        },
        {
          DataverseFieldName: 'lmrv_utmmedium',
          DataverseFieldValue: getCookie('rwa_medium') || '',
        },
        {
          DataverseFieldName: 'lmrv_utmcontent',
          DataverseFieldValue: getCookie('rwa_content') || '',
        },
        {
          DataverseFieldName: 'lmrv_utmterm',
          DataverseFieldValue: getCookie('rwa_term') || '',
        },
        {
          DataverseFieldName: 'lmrv_googleclientid',
          DataverseFieldValue: getCookie('_gcl_au') || '',
        },
        {
          DataverseFieldName: 'lmrv_googleanalyticsid',
          DataverseFieldValue: getCookie('_ga') || '',
        },
        {
          DataverseFieldName: 'lmrv_metaid',
          DataverseFieldValue: getCookie('_fbp') || '',
        },
        {
          DataverseFieldName: 'lmrv_bingid',
          DataverseFieldValue: getCookie('_uetvid') || '',
        },

        {
          DataverseFieldName: 'lmrv_callrailid',
          DataverseFieldValue: getCookie('calltrk_session_id') || '',
        },

        {
          DataverseFieldName: 'lmrv_googleclickid',
          DataverseFieldValue: getCookie('gclid') || '',
        },

        {
          DataverseFieldName: 'lmrv_msclkid',
          DataverseFieldValue: getCookie('msclkid') || '',
        },
        ...props.mappings,
      ]);
      const formPayload = formSerialized.SerializedForm.build();

      if (props.debug) {
        console.log('dynamicsFormSubmit::serializedForm', formSerialized);
        console.log('dynamicsFormSubmit::formPayload', formPayload);
      }

      const response = await client.submitForm(
        {
          FormId: props.dynamicsFormId,
          FormApiUrl: env.DYNAMICS_FORM_API_URL,
        },
        formPayload,
      );

      if (props.debug) {
        console.log('dynamicsFormSubmit::response', response);
      }

      setSubmitted(true);

      if (response.submissionStatus !== 0) {
        setError(true);
        return;
      }

      if (props.callback) {
        props.callback();
      }
    } catch (err) {
      console.error(err);
      setError(true);
    }

    setLoading(false);
  };

  return {
    loading,
    error,
    submitted,
    sendForm,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';

import { useContext, useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

import { LayoutContext } from '@/contexts/LayoutContext';
import { LayoutReducerActionType } from '@/types/layout';
import { getCookie } from '@lamesarv-sdk/tools';

/* -- TagManager Script
<script>
  (function(){
    // -- Script Configuration
    window.lamesarvSettings = window.lamesarvSettings || {};
    window.lamesarvSettings.customUTM = window.lamesarvSettings.customUTM || {};
    window.lamesarvSettings.addCustomUTM
    function addCustomUTM(key, urlParamsList) {
      if (!Array.isArray(urlParamsList)) return;
      if (!urlParamsList.length) return;
      window.lamesarvSettings.customUTM[key] = {
        default: urlParamsList[0],
        fallbacks: urlParamsList.slice(1),
      };
    }
    console.info('UTM Extractor Customization Initialized');
    // -- Script Usage
    // addCustomUTM('utm_custom', ['utm_custom', 'rwa_custom', ...]);
    //               ^- Key         ^- URLParam   ^- Fallbacks
    // -- Example
    // addCustomUTM('utm_custom', ['utm_custom', 'rwa_custom']);
    //
    // -- Script Configuration :: Begin
    addCustomUTM('utm_custom', ['utm_custom', 'rwa_custom']);
    // -- Script Configuration :: End
    console.log('UTM Extractor Customization :: debug', window.lamesarvSettings.customUTM);
  })();
</script>
*/

export interface IMapUTM {}

export const LayoutExtractorUTM = () => {
  const { dispatch } = useContext(LayoutContext);
  const searchParams = useSearchParams();

  const utmMap = {
    utm_campaign: {
      default: 'utm_campaign',
      fallbacks: ['rwa_campaign'],
    },
    utm_content: {
      default: 'utm_content',
      fallbacks: ['rwa_content'],
    },
    utm_medium: {
      default: 'utm_medium',
      fallbacks: ['rwa_medium'],
    },
    utm_source: {
      default: 'utm_source',
      fallbacks: ['rwa_source'],
    },
    utm_term: {
      default: 'utm_term',
      fallbacks: ['rwa_term'],
    },
    adVendor_fbId: {
      default: '_fbp',
    },
    adVendor_gaId: {
      default: '_ga',
    },
    adVendor_bingId: {
      default: '_uetvid',
    },
    adVendor_callRailId: {
      default: 'calltrk_session_id',
    },
    adVendor_gclId: {
      default: 'gclid',
    },
    adVendor_msclkId: {
      default: 'msclkid',
    },
  };

  const extractUTMData = () => {
    const utmMapConfigured = {
      ...utmMap,
      ...((window as any)?.lamesarvSettings?.customUTM ?? {}),
    };
    const utmData = {};

    for (const key in utmMapConfigured) {
      const { default: defaultKey, fallbacks } = utmMap[key];

      // -- Search in url params
      if (searchParams.has(defaultKey)) {
        utmData[key] = searchParams.get(defaultKey);
      } else {
        for (const fallback of fallbacks ?? []) {
          if (searchParams.has(fallback)) {
            utmData[key] = searchParams.get(fallback);
            break;
          }
        }
      }

      // -- Found in search params
      if (!!utmData[key]) continue;

      // -- Search in cookies
      let cookie_value = getCookie(defaultKey);

      if (cookie_value) {
        utmData[key] = cookie_value;
      } else {
        for (const fallback of fallbacks ?? []) {
          cookie_value = getCookie(fallback);
          if (cookie_value) {
            utmData[key] = cookie_value;
            break;
          }
        }
      }

      // -- Fallback (provide a empty string instead of: undefined or null)
      utmData[key] = utmData[key] || '';
    }

    return utmData;
  };

  useEffect(() => {
    const utmData = extractUTMData();

    dispatch({
      type: LayoutReducerActionType.updateUTMData,
      payload: utmData,
    });

    sessionStorage.setItem('lamesarv::utmData', JSON.stringify(utmData));
  }, []);

  return null;
};

interface LogoProps {
  className?: string;
}

export const Logo = ({ className }: LogoProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 41" fill="none" className={className}>
    <path
      d="M67.9912 11.6389C68.9704 12.7051 68.784 14.4315 68.784 14.4315L66.0908 30.8199H63.0827L64.1392 24.1601H61.4378L60.3867 30.8199H57.3787C57.3787 30.8199 59.4379 18.4851 59.7556 16.0529C60.0732 13.6208 60.2334 12.9081 61.3397 11.7991C62.4488 10.69 63.9376 10.3696 65.0867 10.3696C66.1405 10.3696 67.0092 10.5616 67.994 11.6389H67.9912ZM65.7717 13.7561C65.9292 12.8087 65.0895 12.6167 64.6378 12.5877C64.1765 12.5559 63.5868 12.7769 63.2374 13.6539C62.8852 14.5309 61.8052 21.692 61.8052 21.692H64.5066C64.5066 21.692 65.5659 15.0143 65.7703 13.7548L65.7717 13.7561Z"
      fill="#21201F"
    />
    <path
      d="M55.8636 10.5283H59.0319C59.0319 10.5283 56.3415 13.6966 55.8636 14.6496C55.3858 15.6012 54.6179 16.5086 54.6179 17.8427C54.6179 19.1769 55.2283 21.3093 55.3858 22.1021C55.546 22.8921 56.5832 25.6019 55.9617 27.3352C55.6634 28.1652 53.8044 30.8211 53.8044 30.8211H50.6016C50.6016 30.8211 53.0254 27.9484 53.1704 26.5162C53.3251 24.9583 52.6401 22.9819 52.3252 22.062C52.0075 21.145 51.5628 19.2142 51.6526 17.9339C51.7907 15.9409 52.9467 14.3927 53.6262 13.314C54.2878 12.2658 55.8636 10.5297 55.8636 10.5297V10.5283Z"
      fill="#21201F"
    />
    <path
      d="M52.3776 10.3682L51.8445 13.0144H47.1501L45.7234 21.6243H50.3184L49.9993 24.16H45.2483L44.6143 28.1238H49.2093L48.8917 30.8198H40.9709L44.4569 10.3682H52.3776Z"
      fill="#21201F"
    />
    <path
      d="M40.96 8.30742C42.0829 9.53386 41.989 10.6912 41.8688 11.5158C41.1562 16.3718 38.289 30.8197 38.289 30.8197H34.8348L38.4119 10.7396C38.59 9.65125 37.6246 9.43303 37.1109 9.39851C36.5805 9.36398 35.4756 9.31011 35.1773 11.5158L31.6002 30.8197H28.1433L31.7204 10.7396C31.9013 9.65125 30.9359 9.43303 30.4193 9.39851C29.889 9.36398 29.2136 9.61672 28.8117 10.6249L25.2001 30.8197H21.594C21.594 30.8197 23.6532 19.1893 24.6642 13.0378C25.12 10.2589 25.091 9.89847 26.363 8.62646C27.6378 7.35169 29.6086 6.85034 30.929 6.85034C32.0201 6.85034 32.9371 7.02851 33.9343 7.96767C35.1082 7.11414 36.5032 6.85034 37.6205 6.85034C38.8317 6.85034 39.8289 7.06856 40.96 8.30604V8.30742Z"
      fill="#21201F"
    />
    <path
      d="M19.3938 11.6389C20.373 12.7051 20.1865 14.4315 20.1865 14.4315L17.4934 30.8199H14.4853L15.5418 24.1601H12.8404L11.7893 30.8199H8.78125C8.78125 30.8199 10.8405 18.4851 11.1582 16.0529C11.4758 13.6208 11.636 12.9081 12.7423 11.7991C13.8513 10.69 15.3402 10.3696 16.4893 10.3696C17.5431 10.3696 18.4118 10.5616 19.3965 11.6389H19.3938ZM17.1757 13.7561C17.3331 12.8087 16.4934 12.6167 16.0418 12.5877C15.5805 12.5559 14.9908 12.7769 14.6413 13.6539C14.2892 14.5309 13.2091 21.692 13.2091 21.692H15.9106C15.9106 21.692 16.9699 15.0143 17.1743 13.7548L17.1757 13.7561Z"
      fill="#21201F"
    />
    <path
      d="M7.78684 28.2813L7.35178 30.8198C7.35178 30.8198 4.97764 30.8833 4.0951 30.8198C3.44459 30.7687 2.40461 30.6706 1.51103 30.0353C0.676829 29.44 0.348123 28.9732 0.11057 28.3503C-0.030304 27.9747 -0.0275418 26.9789 0.0649933 26.396C0.128525 25.99 3.55094 6.72339 3.55094 6.72339H7.03689L3.55094 27.0037C3.55094 27.0037 3.41973 27.6197 3.8686 27.9636C4.31332 28.3103 4.91963 28.2813 5.34916 28.2813H7.78684Z"
      fill="#21201F"
    />
    <path
      d="M123.576 18.2723L121.203 15.1758H118.691V12.7009H122.334C123.185 12.7009 123.702 12.0945 123.702 11.1526C123.702 10.2107 123.185 9.60439 122.334 9.60439H117.449V18.2737H114.88V6.66675H122.334C124.777 6.66675 126.229 8.37519 126.229 11.0408C126.229 12.9412 125.447 14.2173 124.206 14.7919L127.234 18.2723H123.577H123.576Z"
      fill="#21201F"
    />
    <path
      d="M133.818 18.3041V6.66675H142.095V9.61267H136.089V15.3443H142.12V18.3054H133.818V18.3041ZM137.186 13.5019V11.2769H141.749V13.5019H137.186Z"
      fill="#21201F"
    />
    <path
      d="M154.436 18.2612C151.624 18.2612 149.558 15.7089 149.558 12.3445C149.558 8.98012 151.624 6.66675 154.436 6.66675H159.861V9.60162H154.436C153.049 9.60162 152.023 10.8294 152.023 12.4882C152.023 14.1469 153.023 15.3112 154.436 15.3112H159.861V18.2612H154.436Z"
      fill="#21201F"
    />
    <path
      d="M114.767 21.7224H117.604L121.094 29.2799L124.572 21.7224H127.233L122.11 32.4648C121.834 33.0352 121.496 33.3998 121.006 33.3998C120.515 33.3998 120.164 33.0352 119.888 32.4648L114.766 21.7224H114.767Z"
      fill="#21201F"
    />
    <path
      d="M137.525 25.8145L134.078 33.3734H131.492L136.516 22.6075C136.786 22.0358 137.119 21.6711 137.611 21.6711C138.102 21.6711 138.423 22.0358 138.694 22.6075L143.717 33.3734H140.983L137.524 25.8145H137.525Z"
      fill="#21201F"
    />
    <path
      d="M152.404 26.3726V33.245H150.187V23.3465C150.187 22.29 150.64 21.6284 151.346 21.6284C151.686 21.6284 151.926 21.7707 152.241 22.1325L157.783 28.5161V21.6436H160V31.683C160 32.7395 159.535 33.4011 158.841 33.4011C158.502 33.4011 158.249 33.2588 157.946 32.897L152.404 26.3726Z"
      fill="#21201F"
    />
    <path
      d="M98.8704 17.0941L90.4276 10.3349C90.0699 10.049 89.5658 10.0145 89.1736 10.252L81.1396 15.0943C81.0111 15.1716 80.902 15.2724 80.8219 15.3843L79.1853 17.2723C79.1176 17.3676 79.032 17.4449 78.927 17.5057L72.9813 21.2016C72.8791 21.2651 72.8183 21.3797 72.8252 21.5013C72.9854 24.2856 73.7382 26.9374 75.0613 29.3875C75.1027 29.4634 75.1939 29.4952 75.2726 29.4593C75.6165 29.306 76.5101 28.911 76.9147 28.7591C78.4146 28.1983 79.822 27.976 81.3122 27.7398C84.4639 27.2412 87.7234 26.726 91.7507 22.7042C94.64 19.8204 97.4658 18.1728 98.5306 17.5526C98.5831 17.5223 98.7171 17.4394 98.8525 17.3551C98.9464 17.2971 98.9547 17.1632 98.8676 17.0941H98.8704Z"
      fill="#21201F"
    />
    <path
      d="M91.8184 1.41984C82.0801 1.41984 74.0558 8.75219 72.9633 18.1977C72.944 18.3662 73.1304 18.4822 73.2741 18.3924L75.0958 17.2599C75.1427 17.2309 75.1759 17.1825 75.1869 17.1287C76.7186 9.34331 83.591 3.45008 91.8197 3.45008C94.1621 3.45008 96.394 3.92795 98.4243 4.79115C97.329 5.66954 96.6274 7.01613 96.6274 8.52845C96.6274 11.1788 98.7765 13.3265 101.427 13.3265C103.297 13.3265 104.916 12.2561 105.707 10.694C107.262 12.9135 108.301 15.5197 108.645 18.3385C108.651 18.391 108.679 18.4394 108.719 18.4725C109.458 19.0622 110.024 19.5153 110.459 19.8633C110.595 19.971 110.795 19.8716 110.789 19.6976C110.418 9.53943 102.068 1.41846 91.8197 1.41846L91.8184 1.41984Z"
      fill="#C63926"
    />
    <path
      d="M103.145 16.8911C102.79 16.6108 102.304 16.5693 101.908 16.7876C100.268 17.6908 96.6026 19.8896 94.5848 22.4792C90.871 27.2454 88.1198 29.8571 82.529 31.8031L82.3688 31.8625C81.5097 32.1788 80.7695 32.4522 80.0126 32.8251L79.9643 32.8486C79.9297 32.8652 79.8966 32.8817 79.8648 32.8983C79.5458 33.0571 78.985 33.419 78.5693 33.7049C78.4782 33.7684 78.4657 33.8969 78.5445 33.977C78.6729 34.1096 78.8566 34.2905 78.9602 34.3844C82.4751 37.6107 87.0315 39.3868 91.7908 39.3868C96.5501 39.3868 101.207 37.572 104.739 34.2753C106.961 32.2009 108.672 29.6002 109.687 26.7551C109.841 26.3242 109.98 25.8822 110.102 25.443C110.306 24.7028 110.466 23.9404 110.58 23.178C110.618 22.9377 110.497 22.7802 110.388 22.6711C110.291 22.5745 104.946 18.3096 103.146 16.8884L103.145 16.8911Z"
      fill="#21201F"
    />
  </svg>
);

import '@/styles/autocomplete.css';

import { twMerge } from 'tailwind-merge';

import { searchBlogPosts } from '@/lib/actions';
import { ISuggestions } from '@lamesarv-sdk/components';
import { escapeRegexCharacters, getPostUrl } from '@lamesarv-sdk/tools';
import { BlogPostField } from '@lamesarv-sdk/types';

import { AutoCompleteBlogItem } from './AutoCompleteBlogItem';
import { AutoCompleteInventoryItem } from './AutoCompleteInventoryItem';
import { SdkAutoComplete } from './SdkAutoComplete';

interface AutoCompleteProps {
  className?: string;
  placeholder?: string;
  inverted?: boolean;
  classIcon?: string;
  searchPath?: string;
  defaultSearch?: string;
  onEnter?: (value: string) => void;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  onClick?: (value: string) => void;
  overrideOnEnter?: boolean;
}

export const AutoComplete = ({
  className,
  placeholder,
  inverted,
  classIcon,
  searchPath,
  onEnter,
  onChange,
  defaultSearch,
  onSearch,
  onClick,
  overrideOnEnter,
}: AutoCompleteProps) => {
  const onSuggestionsFetch = async (value: string) => {
    const suggestions: ISuggestions[] = [];

    const blogResults = await searchBlogPosts(escapeRegexCharacters(value));
    const blogSuggestions = blogResults.posts.map((item) => ({
      ...item,
      _type: 'blog',
      _code: item[BlogPostField.title],
      _title: item[BlogPostField.title],
      _description: `${item[BlogPostField.description]?.slice(0, 200)}...`,
      _url: getPostUrl(item),
    }));

    suggestions.push({
      sectionTitle: 'Blog Articles',
      suggestions: blogSuggestions,
    });

    return suggestions;
  };

  return (
    <SdkAutoComplete
      placeholder={placeholder}
      className={twMerge(inverted && 'inverted-autocomplete', className)}
      classIcon={twMerge('text-white', classIcon)}
      onSuggestionsFetch={(value) => {
        if (onSearch) {
          onSearch(value);
        }
        return onSuggestionsFetch(value);
      }}
      searchPath={searchPath}
      defaultSearch={defaultSearch}
      onEnter={onEnter}
      onChange={onChange}
      onClick={onClick}
      overrideOnEnter={overrideOnEnter}
      customSuggestionMap={{
        inventory: AutoCompleteInventoryItem,
        blog: AutoCompleteBlogItem,
      }}
    />
  );
};

'use client';

import { usePathname, useRouter } from 'next/navigation';
import { Fragment, useContext } from 'react';
import { twMerge } from 'tailwind-merge';

import { InventoryForm } from '@/components/Inventory/InventoryForm';
import { InventoryFormContext } from '@/contexts/InventoryFormContext';
import { useEnv } from '@/hooks/useEnv';
import { getUnlockPriceLabel } from '@/tools/inventory';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { getInventoryPageUrl } from '@lamesarv-sdk/tools';
import { InventoryField } from '@lamesarv-sdk/types';
import { IDynamicsOptionSet } from '@/types/dynamicsForms';

export interface IInventoryMobileFormProps {
  locationsOptionSet?: IDynamicsOptionSet;
}

export const InventoryMobileForm = (props: IInventoryMobileFormProps) => {
  const { isMobileInquireOpen: isOpen, item, closeMobileInquire, onItemSubmit } = useContext(InventoryFormContext);
  const { env } = useEnv();
  const router = useRouter();
  const pathname = usePathname();

  const closeModal = () => {
    const inventoryRoute = getInventoryPageUrl(item, '');
    if (pathname !== inventoryRoute) router.push(inventoryRoute);
    closeMobileInquire();
  };

  return (
    <div className="md:hidden">
      <Transition show={isOpen} as={Fragment}>
        <Dialog className="relative z-50" onClose={closeModal}>
          <div
            className={twMerge('fixed inset-0 overflow-hidden', env.ENABLE_NEW_PDP_DESIGN && 'bg-black bg-opacity-60')}
          >
            <div className="absolute inset-0 overflow-hidden">
              <div
                className={twMerge(
                  'pointer-events-none fixed top-0 h-full right-0 flex max-w-full',
                  env.ENABLE_NEW_PDP_DESIGN &&
                    'md:absolute md:top-1/2 md:right-1/2 md:translate-x-1/2 md:-translate-y-1/2 md:h-auto',
                )}
              >
                <TransitionChild
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom={twMerge('translate-x-full', env.ENABLE_NEW_PDP_DESIGN && 'md:translate-x-0')}
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo={twMerge('translate-x-full', env.ENABLE_NEW_PDP_DESIGN && 'md:translate-x-0')}
                >
                  <DialogPanel
                    className={twMerge('pointer-events-auto w-screen', env.ENABLE_NEW_PDP_DESIGN && 'md:w-auto')}
                  >
                    <div
                      className={twMerge(
                        'flex h-full flex-col overflow-y-scroll bg-neutral-100 shadow-xl',
                        env.ENABLE_NEW_PDP_DESIGN && 'bg-white shadow-none md:border md:border-black-100 md:rounded-xl',
                      )}
                    >
                      {env.ENABLE_NEW_PDP_DESIGN && (
                        <div className="max-md:hidden absolute right-16 top-12 z-10">
                          <button type="button" className="relative focus:outline-none" onClick={closeModal}>
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faXmark} className="h-6 w-6 text-black-500" aria-hidden="true" />
                          </button>
                        </div>
                      )}

                      <div
                        className={twMerge(
                          'flex items-start justify-between px-4 py-3 border-b',
                          env.ENABLE_NEW_PDP_DESIGN && 'border-black-200 md:hidden',
                        )}
                      >
                        <DialogTitle
                          className={
                            env.ENABLE_NEW_PDP_DESIGN
                              ? 'text-xl font-bold text-black-500'
                              : 'text-lg font-medium uppercase'
                          }
                        >
                          Vehicle Enquiry
                        </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button type="button" className="relative focus:outline-none" onClick={closeModal}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon
                              icon={faXmark}
                              className={twMerge('h-6 w-6', env.ENABLE_NEW_PDP_DESIGN && 'text-black-500')}
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div
                        className={twMerge(
                          'relative mt-6 flex-1 px-4 pb-6',
                          env.ENABLE_NEW_PDP_DESIGN && 'md:px-16 md:mt-12 md:pb-12',
                        )}
                        data-testid="mobile-form-container"
                      >
                        {env.ENABLE_NEW_PDP_DESIGN ? (
                          <div className="flex flex-col items-center mb-4 md:mb-6">
                            <h2 className="font-bold text-black-500 text-xl md:hidden text-center">
                              {item?.[InventoryField.title]}
                            </h2>
                            <h2 className="font-bold text-black-500 text-xl max-md:hidden">
                              {item && getUnlockPriceLabel(item).buttonLabel}
                            </h2>
                          </div>
                        ) : (
                          <div className="pb-5 border-b mb-5">
                            <span className="font-bold text-sm uppercase" data-testid="form-vehicle-name">
                              {item?.[InventoryField.title]}
                            </span>
                          </div>
                        )}

                        {item && (
                          <InventoryForm
                            onSuccess={() => {
                              closeMobileInquire();
                              if (item) onItemSubmit(item[InventoryField.id]);
                            }}
                            item={item}
                            locationsOptionSet={props.locationsOptionSet}
                            tagItem="lock-price"
                          />
                        )}
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

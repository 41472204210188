'use client';

import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { IBasicInventory } from '@lamesarv-sdk/types';

interface InventoryFormContextData {
  isMobileInquireOpen: boolean;
  item: IBasicInventory | null;
  openMobileInquire: (item: IBasicInventory) => void;
  closeMobileInquire: () => void;
  submittedItems: Set<string>;
  onItemSubmit: (itemId: string) => void;
}

export const InventoryFormContext = createContext({} as InventoryFormContextData);

export const InventoryFormProvider = ({ children }: PropsWithChildren) => {
  const [isMobileInquireOpen, setIsMobileInquireOpen] = useState(false);
  const [item, setItem] = useState<IBasicInventory | null>(null);
  const [submittedItems, setSubmittedItems] = useState(new Set<string>());

  const openMobileInquire = useCallback((item: IBasicInventory) => {
    setItem(item);
    setIsMobileInquireOpen(true);
  }, []);

  const closeMobileInquire = useCallback(() => {
    setIsMobileInquireOpen(false);
  }, []);

  const onItemSubmit = useCallback((itemId: string) => {
    setSubmittedItems((prev) => new Set(prev).add(itemId));
  }, []);

  const contextData = useMemo(
    (): InventoryFormContextData => ({
      isMobileInquireOpen,
      item,
      openMobileInquire,
      closeMobileInquire,
      submittedItems,
      onItemSubmit,
    }),
    [item, isMobileInquireOpen, submittedItems],
  );

  return <InventoryFormContext.Provider value={contextData}>{children}</InventoryFormContext.Provider>;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { useSearchParams } from 'next/navigation';
import { PropsWithChildren } from 'react';

import { InventoryContext } from '@/contexts/InventoryContext';
import { InventoryFiltersProvider } from '@/contexts/InventoryFiltersContext';
import { InventoryProvider } from '@lamesarv-sdk/inventory';
import { getFiltersFromSearchParams } from '@lamesarv-sdk/tools';
import { IFilterBase, InventoryField, InventoryFiltersBase } from '@lamesarv-sdk/types';

interface WrappedInventoryProviderProps {
  customEquipmentFilters: IFilterBase;
  noPriceButtonContent?: string;
  inventoryFiltersBase?: InventoryFiltersBase;
}

export const WrappedInventoryProvider = (props: PropsWithChildren<WrappedInventoryProviderProps>) => {
  const searchParams = useSearchParams();
  const searchDto = getFiltersFromSearchParams(searchParams, false, props.inventoryFiltersBase);

  return (
    <InventoryProvider
      {...searchDto}
      inventoryFiltersBase={props.inventoryFiltersBase}
      clientContext={InventoryContext as any}
      inventoryFiltersCustom={{
        [InventoryField.equipment]: props.customEquipmentFilters,
      }}
    >
      <InventoryFiltersProvider>{props.children as any}</InventoryFiltersProvider>
    </InventoryProvider>
  );
};

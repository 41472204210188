'use client';
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react';

interface LastSearchesContextData {
  lastSearches: string[];
  addSearch: (search: string) => void;
  removeSearch: (search: string) => void;
}

export const LastSearchesContext = createContext({} as LastSearchesContextData);

const LAST_SEARCHES_KEY = 'lastSearches';

export const LastSearchesProvider = ({ children }: PropsWithChildren) => {
  const [lastSearches, setLastSearches] = useState<string[]>([]);

  useEffect(() => {
    const lastSearches = localStorage.getItem(LAST_SEARCHES_KEY);
    if (lastSearches) {
      setLastSearches(JSON.parse(lastSearches));
    }
  }, []);

  const addSearch = (search: string) => {
    const newSearches = [search, ...lastSearches.filter((s) => s !== search)].slice(0, 100);
    setLastSearches(newSearches);
    localStorage.setItem(LAST_SEARCHES_KEY, JSON.stringify(newSearches));
  };

  const removeSearch = (search: string) => {
    const newSearches = lastSearches.filter((s) => s !== search);
    setLastSearches(newSearches);
    localStorage.setItem(LAST_SEARCHES_KEY, JSON.stringify(newSearches));
  };

  const contextValue = useMemo(
    (): LastSearchesContextData => ({
      lastSearches: lastSearches.slice(0, 2),
      addSearch,
      removeSearch,
    }),
    [lastSearches, addSearch, removeSearch],
  );

  return <LastSearchesContext.Provider value={contextValue}>{children}</LastSearchesContext.Provider>;
};

'use client';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Fragment, MouseEvent, useContext, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { AutoComplete } from '@/components/AutoComplete/AutoComplete';
import { Logo } from '@/components/Logo';
import { InventoryContext } from '@/contexts/InventoryContext';
import { LastSearchesContext, LastSearchesProvider } from '@/contexts/LastSearchesContext';
import { IRecommendedSearch } from '@/lib/cachedCms';
import { getFavoritesChannel } from '@/tools/broadcast';
import { IMessageFavorites } from '@/types/inventory';
import { ILayoutHeader } from '@/types/layout';
import { faClock, faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faChevronRight, faPhone, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { getBodyRoute } from '@lamesarv-sdk/tools';
import { IBody } from '@lamesarv-sdk/types';

const HeaderInventoryFavorites = (props: { tagGroup?: string }) => {
  const { context } = useContext(InventoryContext);
  const [itemsQuantity, setItemsQuantity] = useState<number>(0);

  useEffect(() => {
    const channel = getFavoritesChannel();

    channel.onmessage = (message: IMessageFavorites) => {
      setItemsQuantity(message.quantity ?? 0);
    };

    return () => {
      channel.close();
    };
  }, []);

  useEffect(() => {
    if (context?.inventoryFavorites !== undefined) {
      setItemsQuantity(context?.inventoryFavorites?.length);
    }
  }, [context?.inventoryFavorites]);

  return (
    <Link
      href="/favorites"
      className="relative"
      data-testid="header-favorite-btn"
      data-tag-group={props.tagGroup ?? 'header'}
      data-tag="header-favorites"
    >
      <FontAwesomeIcon icon={faHeart} className="size-6 md:size-5 xl:size-6 block" />
      {itemsQuantity > 0 && (
        <span className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 rounded-full flex items-center justify-center">
          <span className="text-xs text-white">{itemsQuantity}</span>
        </span>
      )}
    </Link>
  );
};

const CUSTOM_BODY_ITEMS: { title: string; searchFor: string[] }[] = [
  {
    title: 'Class A',
    searchFor: ['Class A'],
  },
  {
    title: 'Class B',
    searchFor: ['Class B'],
  },
  {
    title: 'Class C',
    searchFor: ['Class C'],
  },
  {
    title: 'Towables',
    searchFor: ['Travel Trailer', '5th Wheel', 'Toy Hauler'],
  },
];

interface SearchMenuProps {
  recommendedSearches: IRecommendedSearch[];
  bodyItems: IBody[];
  closeMenu: (e: MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
}

const SearchMenu = ({ bodyItems, recommendedSearches, closeMenu, className }: SearchMenuProps) => {
  const { lastSearches, removeSearch } = useContext(LastSearchesContext);

  const customBodyItems = CUSTOM_BODY_ITEMS.map((item) => {
    const page = bodyItems.find((p) => item.searchFor.some((s) => p.title.includes(s)));

    if (!page) return null;

    return {
      ...page,
      displayText: item.title,
    };
  });

  return (
    <div className={twMerge('flex flex-col gap-6 font-helvetica', className)}>
      {lastSearches.length > 0 && (
        <div className="flex flex-col divide-y divide-black-100">
          {lastSearches.map((search, index) => (
            <Link
              href={`/search?q=${search}`}
              key={search + index}
              className="flex justify-between items-center py-3.5"
              onClick={closeMenu}
            >
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faClock} className="size-3.5" />
                <span className="text-sm">{search}</span>
              </div>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeSearch(search);
                }}
              >
                <FontAwesomeIcon icon={faXmark} className="size-3.5 text-black-350" />
              </button>
            </Link>
          ))}
        </div>
      )}
      <div className="flex flex-col gap-3">
        <span className="font-bold">RV Types</span>
        <div className="grid grid-cols-4 gap-2">
          {customBodyItems.map((item) => {
            const href = getBodyRoute(item);

            return (
              <Link
                href={href}
                key={item.title}
                onClick={closeMenu}
                className="rounded border border-black-100 flex flex-col items-center px-2 pt-2 pb-2.5 justify-end gap-3 hover:border-black-500"
              >
                <img src={item.imageUrl} alt={item.altText} className="object-cover" />
                <span className="text-xs text-blue-800">{item.displayText}</span>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <span className="font-bold">Recommended</span>
        <div className="flex gap-2.5 flex-wrap">
          {recommendedSearches.map((item, index) => (
            <Link
              href={item.link}
              key={item.text + index}
              onClick={closeMenu}
              className="py-2.5 px-3.5 flex items-center gap-2 border rounded border-black-100 hover:border-black-500 text-blue-800"
            >
              {item.icon &&
                (item.icon.startsWith('<svg') ? (
                  <div dangerouslySetInnerHTML={{ __html: item.icon }} className="size-4" />
                ) : (
                  <img src={item.icon} alt={item.text} className="size-4" />
                ))}
              <span className="text-sm leading-none">{item.text}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

interface MobileTabletSearchProps {
  recommendedSearches: IRecommendedSearch[];
  bodyItems: IBody[];
}

const MobileTabletSearch = ({ recommendedSearches, bodyItems }: MobileTabletSearchProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { addSearch } = useContext(LastSearchesContext);

  return (
    <div
      id="header-mobile-tablet-search"
      className={twMerge(
        'px-6 pb-4 pt-4 md:px-10 xl:hidden top-0 z-40 bg-white border-b border-gray-100',
        isOpen ? 'fixed left-0 w-screen h-screen' : 'sticky',
      )}
    >
      <div className="flex items-center gap-5">
        <div
          className="flex-1"
          role="menu"
          onClick={() => setIsOpen(true)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsOpen(true);
            }
          }}
        >
          <AutoComplete
            className="[&_input]:!pr-4 [&_input]:!pl-12 [&_input]:!py-3 [&_input]:!border-none [&_input]:!bg-black-50 [&_input]:!text-base [&_input]:!font-helvetica [&_input]:!text-black-350 [&_input]:!ring-0 [&_input]:!outline-none"
            classIcon="text-blue-800 left-4"
            placeholder="Make, Model or Keyword"
            onClick={(value) => addSearch(value)}
            onEnter={(value) => addSearch(value)}
          />
        </div>
        <button
          onClick={() => setIsOpen(false)}
          disabled={!isOpen}
          type="button"
          className={twMerge('text-sm underline', isOpen ? 'text-black-500' : 'max-md:hidden text-black-350')}
        >
          Cancel
        </button>
      </div>

      {isOpen && (
        <SearchMenu
          bodyItems={bodyItems}
          recommendedSearches={recommendedSearches}
          closeMenu={() => setIsOpen(false)}
          className="mt-8"
        />
      )}
    </div>
  );
};

interface DesktopSearchProps {
  bodyItems: IBody[];
  recommendedSearches: IRecommendedSearch[];
}

const DesktopSearch = ({ bodyItems, recommendedSearches }: DesktopSearchProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { addSearch } = useContext(LastSearchesContext);

  return (
    <div
      role="menu"
      tabIndex={0}
      className="max-xl:hidden flex-1 relative"
      onClick={() => setIsOpen(true)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setIsOpen(true);
        }
      }}
    >
      {isOpen && (
        <button
          type="button"
          className="fixed bg-black bg-opacity-50 w-screen h-screen top-0 left-0 z-20"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(false);
          }}
        />
      )}

      <AutoComplete
        className="[&_input]:!pr-4 [&_input]:!pl-12 [&_input]:!py-3 [&_input]:!border-none [&_input]:!bg-black-50 [&_input]:!text-base [&_input]:!font-helvetica [&_input]:!text-black-350 [&_input]:!ring-0 [&_input]:!outline-none relative z-40"
        placeholder="Make, Model or Keyword"
        classIcon="text-black-350 left-4"
        onClick={(value) => addSearch(value)}
        onEnter={(value) => addSearch(value)}
      />

      {isOpen && (
        <SearchMenu
          recommendedSearches={recommendedSearches}
          bodyItems={bodyItems}
          closeMenu={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
          className="absolute mt-2 left-0 bg-white z-30 w-full min-w-96 px-6 py-5 rounded"
        />
      )}
    </div>
  );
};

interface LayoutHeaderProps extends ILayoutHeader {
  recommendedSearches: IRecommendedSearch[];
  bodyItems: IBody[];
}

export const LayoutHeader = ({ contact, recommendedSearches, bodyItems, ...props }: LayoutHeaderProps) => {
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    setIsMenuMobileOpen(false);
  }, [pathname]);

  return (
    <LastSearchesProvider>
      <div
        id="layout-header"
        className="text-black-500 font-helvetica inline xl:block xl:border-b xl:border-black-100 xl:pb-4"
      >
        <div
          id="header-navigation"
          className="flex items-center md:border-b md:border-black-100 pt-2 md:pt-3 xl:pt-4 px-6 md:px-10 md:pb-3 xl:border-none xl:max-w-[1800px] xl:px-6 xl:mx-auto xl:w-full xl:pb-0 xl:gap-6 2xl:px-10 2xl:gap-16"
        >
          <div className="flex-1 md:hidden">
            <button type="button" onClick={() => setIsMenuMobileOpen(true)} className="outline-none">
              <FontAwesomeIcon icon={faBars} className="size-6" />
            </button>
          </div>
          <Link href="/">
            <Logo className="w-40 md:w-[150px] xl:w-[180px]" />
          </Link>
          <div className="flex-1 flex items-center gap-2.5 justify-end md:gap-5 xl:gap-8">
            <nav className="max-md:hidden flex items-center gap-2 text-sm xl:text-base xl:gap-2 2xl:gap-5 xl:px-2">
              {props.navBarLinks.map((link, index) => (
                <Link
                  href={link.url}
                  key={link.title + index}
                  className={twMerge(
                    'px-1 border-b-[1.5px] border-transparent',
                    (pathname === link.url || link.childrenPages?.includes(pathname)) && 'font-medium border-black-500',
                  )}
                  data-testid={link.title}
                >
                  <span>{link.title}</span>
                </Link>
              ))}
            </nav>

            <DesktopSearch bodyItems={bodyItems} recommendedSearches={recommendedSearches} />

            {contact?.map((item, index) => (
              <a
                href={item.url}
                key={item.title + index}
                className="flex flex-col gap-1 max-xl:hidden"
                data-testid={`${item.title}-header-link`}
              >
                <span>{item.title}</span>
                <div className="flex gap-2 items-center">
                  <FontAwesomeIcon icon={faPhone} className="size-3.5" />
                  <span>{item.text}</span>
                </div>
              </a>
            ))}

            <a href={(contact[0] as { url: string }).url} className="xl:hidden">
              <FontAwesomeIcon
                icon={faPhone}
                className="size-5 md:size-4 block"
                data-testid="mobile-phone-header-link"
              />
            </a>

            <HeaderInventoryFavorites />
          </div>
        </div>

        <MobileTabletSearch recommendedSearches={recommendedSearches} bodyItems={bodyItems} />

        <Transition show={isMenuMobileOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40" onClose={() => setIsMenuMobileOpen(false)}>
            <TransitionChild
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <button
                type="button"
                className="fixed inset-0 bg-black-500 bg-opacity-75 outline-none"
                onClick={() => setIsMenuMobileOpen(false)}
              />
            </TransitionChild>
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col bg-white w-4/5 px-8 py-6 font-helvetica text-black-500">
                <div className="flex items-center justify-between">
                  <Link href="/">
                    <Logo className="w-40" />
                  </Link>
                  <button type="button" onClick={() => setIsMenuMobileOpen(false)}>
                    <FontAwesomeIcon icon={faXmark} className="size-6" />
                  </button>
                </div>
                <div className="flex flex-col mt-10 gap-3.5">
                  {props.sidebarLinks.map((link, index) => (
                    <Link href={link.url} key={link.title + index} className="flex items-center justify-between pr-1">
                      <span className="text-xl font-medium">{link.title}</span>
                      <FontAwesomeIcon icon={faChevronRight} className="size-4" />
                    </Link>
                  ))}
                </div>
                <Link
                  href="/#stay-updated"
                  className="mt-12 flex flex-col text-xl"
                  onClick={() => setIsMenuMobileOpen(false)}
                >
                  <span className="text-black-300">Get in on the latest—</span>
                  <div className="flex gap-2.5">
                    <span>Sign Up</span>
                    <span className="text-black-300">now!</span>
                  </div>
                </Link>
                <div className="mt-12 flex flex-col gap-3.5">
                  <Link href="/contact-us" className="flex items-center justify-between pr-1">
                    <span className="text-xl font-medium">Contact</span>
                    <FontAwesomeIcon icon={faChevronRight} className="size-4" />
                  </Link>
                  <Link href="/career/employment" className="flex items-center justify-between pr-1">
                    <span className="text-xl font-medium">Careers</span>
                    <FontAwesomeIcon icon={faChevronRight} className="size-4" />
                  </Link>
                  <Link href="/about-la-mesa-rv/company-history" className="flex items-center justify-between pr-1">
                    <span className="text-xl font-medium">About Us</span>
                    <FontAwesomeIcon icon={faChevronRight} className="size-4" />
                  </Link>
                  <Link href="/blog" className="flex items-center justify-between pr-1">
                    <span className="text-xl font-medium">Blog</span>
                    <FontAwesomeIcon icon={faChevronRight} className="size-4" />
                  </Link>
                </div>
              </div>
            </TransitionChild>
          </Dialog>
        </Transition>
      </div>
    </LastSearchesProvider>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  IComponentGridInventory,
  IInventoryDetailsSettings,
  IInventorySettings,
  ILayoutBase,
  ILink,
  ILinkGroup,
  InventoryLocations,
} from '@lamesarv-sdk/types';

// -- Layout Utilities

export interface ILayoutGridSettings {
  inventoryNotFound: IInventorySettings;
  inventorySold: IInventorySettings;
  inventoryNotAvailable: IInventorySettings;
  inventoryLanding: IComponentGridInventory;
}

export interface INavBarLink extends ILink {
  childrenPages?: string[];
}

// -- Layout

export interface ILayoutHeader {
  slogan: string;
  searchPlaceholder?: string;
  sidebarLinks: ILink[];
  topBarLinks: ILink[];
  navBarLinks: INavBarLink[];
  contact: {
    title: string;
    text: string;
    url: string;
  }[];
}

export interface ILayoutFooter {
  copyright: string;
  emailOfferTitle: string;
  rvShoppingLinks: ILinkGroup;
  rvResourcesLinks: ILinkGroup;
  aboutLinks: ILinkGroup;
  locationLinks: ILinkGroup[];
  socialLinks: ILinkGroup;
  footerLinks: ILink[];
}

export interface ILayout extends ILayoutBase {
  header: ILayoutHeader;
  footer: ILayoutFooter;
  gridSettings: ILayoutGridSettings;
  inventoryDetailsSettings: IInventoryDetailsSettings & { subscribeCheckboxContent: string };
  isPreview?: boolean;
  noPriceButtonContent?: string;
}

// -- Context

export interface ILayoutContextData {
  geoLocation?: [number, number];
  isGeoLocationLoading?: boolean;
  isComparisonModalOpen?: boolean;
  noPriceButtonContent?: string;
  utmData?: { [utmKey: string]: string };
  inventoryLocations: InventoryLocations;
  detailsSettings: IInventoryDetailsSettings & { subscribeCheckboxContent: string };
}

export enum LayoutReducerActionType {
  updateGeoLocation = 'updateGeoLocation',
  updateIsGeoLocationLoading = 'updateIsGeoLocationLoading',
  updateIsComparisonModalOpen = 'updateIsComparisonModalOpen',
  updateUTMData = 'updateUTMData',
  clear = 'clear',
}

export interface ILayoutReducerAction {
  type: LayoutReducerActionType;
  payload?: any;
}

export type IDynamicsFormMapping =
  | {
      FormFieldName: string;
      DataverseFieldName: string;
    }
  | {
      DataverseFieldName: string;
      DataverseFieldValue: string;
    };

export interface IDynamicsFormConfig {
  FormId: string;
  FormApiUrl: string;
}

export type IDynamicsOptionSet = {
  DataverseValue: number;
  Label: string;
}[];

export enum DynamicsLeadType {
  buyer = 10,
  seller = 11,
  service = 12,
  parts = 13,
}

export enum DynamicsDealershipType {
  phoenix = 10,
  mesa = 11,
  tucson = 12,
  fremont = 13,
  westSacramento = 14,
  sanDiego = 15,
  albuquerque = 16,
  orlando = 17,
  portStLucie = 18,
  ftMyers = 19,
  davie = 20,
}

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { defaultImageUrl } from '@/constants/inventory';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISuggestion } from '@lamesarv-sdk/components';
import { getInventoryImage } from '@lamesarv-sdk/tools';
import { IBasicInventory, InventoryField } from '@lamesarv-sdk/types';

export interface ItemSuggestionProps {
  suggestion: ISuggestion & IBasicInventory;
  query: string;
}

export const AutoCompleteInventoryItem = ({ suggestion, query }: ItemSuggestionProps) => {
  const matches = match(suggestion._title ?? '', query);
  const parts = parse(suggestion._title ?? '', matches);

  return (
    <div className="flex flex-row gap-3.5 font-helvetica text-black-500">
      <img className="w-[100px] h-auto" src={getInventoryImage({ item: suggestion, defaultImageUrl })} alt="" />
      <div className="leading-none flex flex-col gap-1">
        <span className="text-blue-700 text-sm font-bold">
          {parts.map((part) =>
            part.highlight ? (
              <span key={part.text} className="bg-blue-100">
                {part.text}
              </span>
            ) : (
              part.text
            ),
          )}
        </span>
        <span className="uppercase text-xs text-black-350 font-medium">{suggestion[InventoryField.stockNumber]}</span>
        <span className="text-xs text-blue-700 font-medium">
          {suggestion[InventoryField.body]} - {suggestion[InventoryField.fuelType]}
        </span>
        <div className="flex flex-row text-xs h-4 text-black-350">
          <FontAwesomeIcon icon={faLocationDot} className="flex w-4 mr-0.5" /> {suggestion[InventoryField.location]}
        </div>
      </div>
    </div>
  );
};

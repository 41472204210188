import { ISuggestion } from '@lamesarv-sdk/components';
import { IBlogPostData } from '@lamesarv-sdk/types';

export interface ItemSuggestionProps {
  suggestion: ISuggestion & IBlogPostData;
  query: string;
}

export const AutoCompleteBlogItem = ({ suggestion }: ItemSuggestionProps) => {
  return (
    <div className="flex flex-col gap-2 font-helvetica text-black-500">
      <div className="text-sm font-bold">{suggestion._title}</div>
      <div className="text-xs text-blue-800">{suggestion._description}</div>
    </div>
  );
};

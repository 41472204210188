import { useEffect, useState } from 'react';

import { deviceIdName } from '@/constants/settings';

export const useDeviceId = () => {
  const [deviceId, setDeviceId] = useState(null);

  useEffect(() => {
    const deviceId = localStorage.getItem(deviceIdName);

    if (deviceId) {
      setDeviceId(deviceId);
      return;
    }

    const getDeviceId = async () => {
      const { id: newDeviceId } = await fetch('/api/id').then((res) => res.json());

      setDeviceId(newDeviceId);
      localStorage.setItem(deviceIdName, newDeviceId);
    };

    getDeviceId();
  }, []);

  return deviceId;
};
